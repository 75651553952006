import React, { useState, useRef } from "react";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import VideoRecorder from "react-video-recorder";
import { useDispatch } from "react-redux";
import CircularProgressBar from "../../components/CircularProgressBar";
import { VipvApi } from "../../redux/services/user";
import { startLoading, stopLoading } from "../../redux/actions/loader";

const SubmitButton = styled(Button)({
  backgroundColor: "#6857e5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#6857e5",
  },
  ":disabled": {
    backgroundColor: "#6857e5",
    color: "#fff",
    opacity: 0.5,
  },
});

const VipvPage = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataResp, setDataResp] = useState();
  const fileInputRef = useRef();
  const initialValues = {
    // type: "",
    file: "",
    video: "",
  };
  const validationSchema = yup.object().shape({
    // type: yup.string().required("Address type is required"),
    file: yup.string().required("Upload file is required"),
    video: yup.string().required("Upload video is required"),
  });

  const onSubmit = async (value, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setLoading(true);
    dispatch(startLoading());
    const formData = new FormData();
    formData.append("document_files", value.file);
    formData.append("live_video", value.video);
    try {
      const resp = await VipvApi(formData);
      console.log(resp.data);
      setDataResp(resp.data?.message);
      setLoading(false);
      dispatch(stopLoading());
      resetForm();
    } catch (err) {
      console.log("err", err);
      toast.error(err.response?.data?.message || "Invalid Photo proof or Video Quality");
      setLoading(false);
      dispatch(stopLoading());
      setSubmitting(false);
    }
  };

  const handleUploadFile = (file, setFieldValue) => {
    if (!file) return;
    const allowed_types = ["image/png", "image/jpeg"];
    if (!allowed_types.includes(file.type)) {
      fileInputRef.current.value = "";
      setFieldValue("file", "");
      toast.error("Only Images are allowed (JPG | PNG)");
      return false;
    }
    setFieldValue("file", file);
  };

  const getConfidenceScore = (score) => {
    return parseInt(score * 100) || 0;
  };

  return (
    <div className={`my-2 ${loading ? "pointer-none" : ""}`}>
      {dataResp?.status === "Fail" ? (
        <div className="flex flex-col items-center justify-center">
          <h4 className="my-4">
            {dataResp?.content === "list index out of range"
              ? "Invalid Photo Proof or Video Quality"
              : dataResp?.content}
          </h4>
          <SubmitButton type="button" variant="outlined" onClick={() => setDataResp("")}>
            Try Again
          </SubmitButton>
        </div>
      ) : dataResp?.status === "Success" ? (
        <div className="flex flex-col items-center justify-center">
          <img
            src={`data:image/png;base64,${dataResp?.content?.replace(
              new RegExp(["b'", "'"].join("|"), "g"),
              ""
            )}`}
            alt=""
          />
          <h4 className="mt-4 mb-3">
            <b>
              Face matched with proof -{" "}
              {getConfidenceScore(dataResp?.result?.confidence) <= 30
                ? "Not Matched"
                : getConfidenceScore(dataResp?.result?.confidence) <= 90
                ? "Partial"
                : "Complete"}
            </b>{" "}
          </h4>
          <CircularProgressBar value={getConfidenceScore(dataResp?.result?.confidence)} />
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
            <FormikForm className="my-2">
              {/* <Form.Group className="mb-3">
              <Form.Label>Address Type</Form.Label>
              <Field
                name="type"
                render={({ field }) => (
                  <Form.Select
                    name="type"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <option value="">Select an option</option>
                    <option value="Aadhaar">Aadhaar</option>
                    <option value="Pan">Pan</option>
                    <option value="DrivingLicense">Driving Licence</option>
                    <option value="VoterID">Voter Id</option>
                    <option value="Passport">Passport</option>
                  </Form.Select>
                )}
              />
              <ErrorMessage
                name="type"
                className="input-error"
                component="div"
              />
            </Form.Group> */}
              <Form.Group className="mb-3">
                <Form.Label>Upload File (Photo proof - jpg, png)</Form.Label>
                <Field
                  render={() => (
                    <Form.Control
                      ref={fileInputRef}
                      type="file"
                      onChange={({ target }) =>
                        handleUploadFile(target.files[0], setFieldValue)
                      }
                      accept="image/*"
                      autoFocus={true}
                    />
                  )}
                />
                <ErrorMessage name="file" className="input-error" component="div" />
              </Form.Group>
              {values.file && (
                <Form.Group>
                  <Form.Label>Upload Video</Form.Label>
                  <VideoRecorder
                    isOnInitially={true}
                    replayVideoAutoplayAndLoopOff={true}
                    onRecordingComplete={(blob) => setFieldValue("video", blob)}
                    onStopReplaying={() => setFieldValue("video", "")}
                    timeLimit={10000}
                    countdownTime={0}
                  />
                  <ErrorMessage name="video" className="input-error" component="div" />
                </Form.Group>
              )}
              <div className="text-end mt-4 mb-2">
                <Button
                  type="button"
                  variant="outlined"
                  className="mx-2"
                  disabled={loading}
                  onClick={() => handleClose()}
                >
                  Close
                </Button>
                <SubmitButton
                  type="submit"
                  variant="outlined"
                  disabled={!isValid || !dirty || isSubmitting || loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </SubmitButton>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </div>
  );
};

export default VipvPage;
