import React from "react";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/auth";

const SubmitButton = styled(Button)({
  backgroundColor: "#6857e5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#6857e5",
  },
  ":disabled": {
    backgroundColor: "#6857e5",
    color: "#fff",
    opacity: 0.5,
  },
});

const Login = ({ handleModal }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const initialValues = {
    entity: "",
    password: "",
  };
  const validationSchema = yup.object().shape({
    entity: yup.string().required("Email or Phone is required"),
    password: yup.string().min(6).required("Password is required"),
  });
  const onLogin = async (value, { setSubmitting }) => {
    setSubmitting(true);
    let payload = {
      password: value.password,
    };
    if (value.entity.includes("@")) {
      payload["entity"] = value.entity;
    } else {
      payload["entity"] = `91${value.entity}`;
    }
    dispatch(loginUser(payload, handleModal));
  };

  return (
    <div className={`my-2 ${loading ? "pointer-none" : ""}`}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onLogin}
      >
        {() => (
          <FormikForm className="my-2">
            <Form.Group className="mb-3">
              <Form.Label>Email or Phone</Form.Label>
              <Field
                name="entity"
                render={({ field }) => (
                  <Form.Control
                    name="entity"
                    type="text"
                    placeholder="Enter email or phone"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <ErrorMessage
                name="entity"
                className="input-error"
                component="div"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Field
                name="password"
                render={({ field }) => (
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <ErrorMessage
                name="password"
                className="input-error"
                component="div"
              />
            </Form.Group>
            <div className="text-end mt-4 mb-2">
              <Button
                type="reset"
                variant="outlined"
                className="mx-2"
                disabled={loading}
              >
                Clear
              </Button>
              <SubmitButton type="submit" variant="outlined" disabled={loading}>
                {loading ? "Loading..." : "Submit"}
              </SubmitButton>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default Login;
