import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { GeolocationApi } from "../../redux/services/user";

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const SubmitButton = styled(Button)({
  backgroundColor: "#6857e5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#6857e5",
  },
  ":disabled": {
    backgroundColor: "#6857e5",
    color: "#fff",
    opacity: 0.5,
  },
});

const GeolocationPage = () => {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState("");
  const [latLong, setLatLong] = useState({
    lat: 0,
    lng: 0,
  });

  const success = async (pos) => {
    let crd = pos.coords;
    console.log("Your current position is:", crd);
    const payload = {
      lat: crd.latitude.toString(),
      long: crd.longitude.toString(),
    };
    setLatLong({
      lat: crd.latitude,
      lng: crd.longitude,
    });
    try {
      const resp = await GeolocationApi(payload);
      console.log(resp.data);
      setLocation(resp.data?.data);
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const errors = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    setLoading(false);
    toast.error(err.message || "Something went wrong");
  };

  const getPosition = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then(function (result) {
        if (result.state === "granted") {
          console.log(result.state);
          navigator.geolocation.getCurrentPosition(success);
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === "denied") {
          setLoading(false);
          toast.error("Please enable location permission. ");
        }
        result.onchange = function () {
          console.log(result.state);
        };
      });
    } else {
      alert("Sorry Not available!");
    }
  };

  useEffect(() => {
    getPosition();
  }, []);

  const openWithGoogleMap = () => {
    console.log(`https://www.google.com/maps?q=${latLong.lat},${latLong.lng}`);
    const win = window.open(
      `https://www.google.com/maps?q=${latLong.lat},${latLong.lng}`,
      "_blank"
    );
    win.focus();
  };

  return (
    <div className="flex items-center justify-center">
      {loading && <Loader />}
      {location && (
        <div className="flex flex-col items-center justify-center my-4">
          <div className="flex">
            <p>
              <b>You are in / around : &nbsp;</b>
            </p>
            <p> {location.city}</p>
          </div>
          {/* <div className="flex">
            <SubmitButton
              type="button"
              variant="outlined"
              onClick={() => openWithGoogleMap()}
            >
              Open on google map
            </SubmitButton>
          </div> */}
        </div>
      )}
    </div>
  );
};
export default GeolocationPage;
