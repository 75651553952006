import { AUTH_TYPES } from "../types/auth";

const initialState = {
  isLoggedIn: false,
  loading: false,
  auth: null,
  isBroker: false,
  broker: null,
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_TYPES.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AUTH_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        auth: action.payload,
        isLoggedIn: true,
      };
    case AUTH_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
      };
    case AUTH_TYPES.IS_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case AUTH_TYPES.LOGOUT_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        auth: null,
      };
    case AUTH_TYPES.BROKER_DETAIL_REQUEST:
      return {
        ...state,
        isBroker: true,
      };
    case AUTH_TYPES.BROKER_DETAIL_SUCCESS:
      return {
        ...state,
        broker: action.payload,
        isBroker: false,
      };
    case AUTH_TYPES.BROKER_DETAIL_FAILURE:
      return {
        ...state,
        isBroker: false,
      };
    default:
      return state;
  }
}
