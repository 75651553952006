import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CircularProgress } from "@material-ui/core";
const Modal = ({ open, onClose, title, content, loading }) => {
  const handleClose = (_event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    if (reason === "escapeKeyDown") {
      return false;
    }
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
      <Box>
        {loading && (
          <Box className="modal-spinner" sx={{ minWidth: 100, minHeight: 100 }}>
            <Card>
              <CardContent
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </CardContent>
            </Card>
          </Box>
        )}
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box className="text-center" style={{ fontWeight: "600" }}>
            {title}
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={loading}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
      </Box>
    </Dialog>
  );
};
export default Modal;
