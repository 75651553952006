import { API_URL } from "../../config";
import axios from "axios";

export const LoginApi = (payload) => {
  return axios.post(
    `${API_URL}/api/method/central_system.apis.authentication.api.login_via_password`,
    payload
  );
};

export const SignupApi = (payload) => {
  return axios.post(
    `${API_URL}/api/method/central_system.apis.authentication.api.request_signup_otp`,
    payload
  );
};

export const verifySignupOtpApi = (payload) => {
  return axios.post(
    `${API_URL}/api/method/central_system.apis.authentication.api.verify_signup_otp`,
    payload
  );
};

export const verifySignupDetailApi = (payload, headers) => {
  return axios.post(
    `${API_URL}/api/method/central_system.apis.broker_signup_page.api.save_signup_details`,
    payload,
    headers
  );
};

export const resendOtpApi = (payload) => {
  return axios.post(
    `${API_URL}/api/method/central_system.apis.authentication.api.resend_signup_otp`,
    payload
  );
};

export const getBrokerDetail = (payload) => {
  return axios.post(
    `${API_URL}/api/method/central_system.apis.broker_details.api.get_broker_details_for_single_signon`,
    payload
  );
};
