import React, { useState } from "react";
import DisclaimerModal from "../../pages/Disclamer";
import PrivacyModal from "../../pages/Privacy";
const Footer = () => {
  const [openDis, setOpenDis] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  return (
    <>
      <section className="back-blue pt-5">
        <div className="container ">
          <div className="text-center">
            {" "}
            <img src="/assets/img/kyc-logo.svg" alt=""></img>
          </div>

          <div className="row mt-5">
            <div className="col-md-4 text-white d-flex align-items-center">
              <img src="assets/img/icons/email.png" alt="" className="m-2" />
              <a
                href="mailto:support@smartkyc.in"
                rel="noopener noreferrer"
                className="text-white"
              >
                support@smartkyc.in
              </a>
            </div>
            <div className="col-md-4 text-white d-flex align-items-center middle-f-d">
              <img src="assets/img/icons/call.png" alt="" className="m-2" />
              <a
                href="tel:919870210171"
                rel="noopener noreferrer"
                className="text-white"
              >
                +91 9870 210 171
              </a>
            </div>
            <div className="col-md-4 text-white d-flex align-items-center">
              <img src="assets/img/icons/location.png" alt="" className="m-2" />
              <div className="mt-3">
                36/227, RDP 10, Sector VI, Charkop, Kandivali (W), Mumbai,
                Maharashtra - 400 067, India
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 mx-auto">
              <div className="text-white text-center d-flex justify-content-center mt-5 align-items-center footer-text ">
                <div  className="text-end ">
                  <img
                    src="/assets/img/banner/lion.png"
                    alt=""
                    width={"50"}
                    className="m-2"
                  />
                  Make In India
                </div>
                <div className="seperator mx-3"></div>{" "}
                <div  className="text-start ">
                  Powered by SecMark Consultancy Limited
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-center  d-flex py-2 "
          style={{ background: "#322f4361" }}
        >
          <div className="container py-1">
            <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column">
              <p className="mr-auto text-white m-0">
                ©2022{" "}
                <span className="smart-kyc">
                  SMART<b>KYC</b>
                </span>
                , All Rights Reserved.
              </p>
              <div className="d-flex">
                <span
                  role="button"
                  className="text-white mx-2"
                  onClick={() => setOpenDis(true)}
                >
                  Disclaimer
                </span>
                <span
                  role="button"
                  className="text-white mx-2"
                  onClick={() => setOpenPrivacy(true)}
                >
                  Privacy Policy
                </span>
              </div>
            </div>
          </div>
          &nbsp; &nbsp;
        </div>
      </section>
      <DisclaimerModal isOopen={openDis} setIsOpen={setOpenDis} />
      <PrivacyModal isOopen={openPrivacy} setIsOpen={setOpenPrivacy} />
    </>
  );
};
export default Footer;
