// import firebase from "firebase/app";
// import "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyDW83Iq2sYDYa9UmEC43TYyYi1eT2IRUzg",
//   authDomain: "workflow-5a59b.firebaseapp.com",
//   projectId: "workflow-5a59b",
//   storageBucket: "workflow-5a59b.appspot.com",
//   messagingSenderId: "836287056465",
//   appId: "1:836287056465:web:1f166dd851a3a0993c8670",
//   measurementId: "G-HC1996EEJK",
// };

// firebase.initializeApp(firebaseConfig);

// const storage = firebase.storage();

// export { firebase as default, storage };

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyDW83Iq2sYDYa9UmEC43TYyYi1eT2IRUzg",
  authDomain: "workflow-5a59b.firebaseapp.com",
  projectId: "workflow-5a59b",
  storageBucket: "workflow-5a59b.appspot.com",
  messagingSenderId: "836287056465",
  appId: "1:836287056465:web:1f166dd851a3a0993c8670",
  measurementId: "G-HC1996EEJK",
});

const storage = getStorage(app);
export default storage;
