import React from "react";

const CardSection = () => {
  const data = [
    {
      icon: "assets/img/icons/id.png",
      title: "Aadhar Based eKYC",
      decs: "Aadhar based eKYC enables you to onboard client easily using integrated digi locker facility though inbuilt APIs",
    },
    {
      icon: "assets/img/icons/editfile.png",
      title: "KRA Based Fetching",
      decs: "KRA based fetching integration enables to fetch the client details from KRA which help in onboarding client smartly",
    },
    {
      icon: "assets/img/icons/file.png",
      title: "Documents Submission eKYC",
      decs: "Enables client to upload scan copy of respective documents online and digitally sign the documents",
    },
  ];
  return (
    <section className="py-5 my-5 border-top  comman-image-card border-gradient  no-border-bottm bg-gradient">
      <div className="container  text-wrap">
        <h1 className="text-wrap text-center mx-15 onboard-section-title">
          Onboarding client with Aadhar based eKYC / KRA based fetching /
          Submission of online documents
        </h1>
        <div className="d-flex flex-row justify-content-evenly mt-10">
          {data.map((val, index) => {
            return (
              <div className="card w-30 p-3" key={index}>
                <div className="icon text-center card-icon">
                  <img
                    src={val.icon}
                    alt=""
                    width="65px"
                    height="65 px"
                    className="bg-purple p-3"
                  ></img>
                </div>
                <h3 className="text-center text-wrap pt-3 mt-4">{val.title}</h3>
                <div className="card-desc-text text-wrap px-3 pb-3">
                  <p>
                  {val.decs}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="border-top border-info  margin-bottm-minus"></div>
    </section>
  );
};

export default CardSection;
