import React, { useState, useRef } from "react";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { OcrApi } from "../../redux/services/user";
import { startLoading, stopLoading } from "../../redux/actions/loader";

const SubmitButton = styled(Button)({
  backgroundColor: "#6857e5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#6857e5",
  },
  ":disabled": {
    backgroundColor: "#6857e5",
    color: "#fff",
    opacity: 0.5,
  },
});

const OcrPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataResp, setDataResp] = useState();
  const file1InputRef = useRef();
  const file2InputRef = useRef();
  const initialValues = {
    type: "",
    file1: "",
    file2: "",
    file1Base64: "",
    file2Base64: "",
    file_type: "Image",
  };
  const [validationSchema, setValidationSchema] = useState(
    yup.object().shape({
      type: yup.string().required("Document type is required"),
      file1: yup.string().required("Front image is required"),
    })
  );

  const handleCheckType = (type) => {
    if (["PAN", "VoterID"].includes(type)) {
      setValidationSchema(
        yup.object().shape({
          type: yup.string().required("Document type is required"),
          file1: yup.string().required("Front image is required"),
        })
      );
    } else {
      setValidationSchema(
        yup.object().shape({
          type: yup.string().required("Address type is required"),
          file1: yup.string().required("Front image is required"),
          file2: yup.string().required("Back image is required"),
        })
      );
    }
  };

  const onSubmit = async (value, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    console.log("value", value);
    setLoading(true);
    dispatch(startLoading());
    const formData = new FormData();
    formData.append("type", value.type);
    formData.append("file1", value.file1);
    value.file2 && formData.append("file2", value.file2);
    formData.append("file_type", value.file_type);
    try {
      const resp = await OcrApi(formData);
      console.log(resp.data);
      setDataResp(resp.data);
      setLoading(false);
      dispatch(stopLoading());
      resetForm();
    } catch (err) {
      console.log("err", err);
      toast.error(err.response?.data?.message || "Something went wrong");
      setLoading(false);
      dispatch(stopLoading());
      setSubmitting(false);
    }
  };

  const handleReset = () => {
    file1InputRef.current.value = "";
    if (file2InputRef?.current) file2InputRef.current.value = "";
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleUploadFile = async (key, file, setFieldValue) => {
    if (!file) return;
    const allowed_types = ["image/png", "image/jpeg"];
    if (!allowed_types.includes(file.type)) {
      if (key === "file1") {
        file1InputRef.current.value = "";
      }
      if (key === "file2") {
        file2InputRef.current.value = "";
      }
      setFieldValue(key, "");
      toast.error("Only Images are allowed (JPG | PNG)");
      return false;
    }
    setFieldValue(key, file);
    try {
      const resp = await getBase64(file);
      console.log("resp", resp);
      setFieldValue(
        key + "Base64",
        resp.replace("data:image/jpeg;base64,", "")
      );
    } catch (err) {
      console.error("error", err);
    }
  };

  return (
    <div className={`my-2 ${loading ? "pointer-none" : ""}`}>
      {dataResp?.code === 200 ? (
        <div className="flex flex-col unselectable">
          {Object.entries(dataResp?.data).length === 0 ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-center">Invalid Document.</p>
              <SubmitButton
                type="button"
                variant="outlined"
                onClick={() => setDataResp("")}
              >
                Try Again
              </SubmitButton>
            </div>
          ) : (
            Object.entries(dataResp?.data).map(([key, value]) => {
              return (
                <div className="row my-1" key={key}>
                  <div className="col-md-3">
                    <b>{capitalizeFirstLetter(key?.replace(/_/g, " "))}</b>
                  </div>
                  <div className="col-md-1">
                    <b>:</b>
                  </div>
                  <div className="col-md-8">{value}</div>
                </div>
              );
            })
          )}
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
            <FormikForm className="my-2">
              <Form.Group className="mb-3">
                <Form.Label>Document Type</Form.Label>
                <Field
                  name="type"
                  render={({ field }) => (
                    <Form.Select
                      name="type"
                      value={field.value}
                      onChange={({ target }) => {
                        setFieldValue("type", target.value);
                        setFieldValue("file1", "");
                        setFieldValue("file2", "");
                        handleReset();
                        handleCheckType(target.value);
                      }}
                      autoFocus={true}
                    >
                      <option value="">Select an option</option>
                      <option value="Aadhaar">Aadhaar</option>
                      <option value="PAN">PAN</option>
                      <option value="Passport">Passport</option>
                      <option value="DrivingLicense">Driving Licence</option>
                      <option value="VoterID">Voter Id</option>
                    </Form.Select>
                  )}
                />
                <ErrorMessage
                  name="type"
                  className="input-error"
                  component="div"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Upload Front Image</Form.Label>
                <Field
                  render={() => (
                    <Form.Control
                      ref={file1InputRef}
                      type="file"
                      onChange={({ target }) =>
                        handleUploadFile(
                          "file1",
                          target.files[0],
                          setFieldValue
                        )
                      }
                      accept="image/*"
                    />
                  )}
                />
                <ErrorMessage
                  name="file1"
                  className="input-error"
                  component="div"
                />
              </Form.Group>
              {!["PAN", "VoterID"].includes(values.type) && (
                <Form.Group className="mb-3">
                  <Form.Label>Upload Back Image</Form.Label>
                  <Field
                    render={() => (
                      <Form.Control
                        ref={file2InputRef}
                        type="file"
                        onChange={({ target }) =>
                          handleUploadFile(
                            "file2",
                            target.files[0],
                            setFieldValue
                          )
                        }
                        accept="image/*"
                      />
                    )}
                  />
                  <ErrorMessage
                    name="file2"
                    className="input-error"
                    component="div"
                  />
                </Form.Group>
              )}
              <div className="text-end mt-4 mb-2">
                <Button
                  type="reset"
                  variant="outlined"
                  className="mx-2"
                  disabled={loading}
                  onClick={() => handleReset()}
                >
                  Clear
                </Button>
                <SubmitButton
                  type="submit"
                  variant="outlined"
                  disabled={!isValid || !dirty || isSubmitting || loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </SubmitButton>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </div>
  );
};

export default OcrPage;
