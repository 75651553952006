import { LOADER_TYPES } from "../types/loader";

export function startLoading() {
  return async (dispatch) => {
    dispatch({ type: LOADER_TYPES.LOADING_START });
  };
}

export function stopLoading() {
  return async (dispatch) => {
    dispatch({ type: LOADER_TYPES.LOADING_STOP });
  };
}
