import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";
import Routes from "./routes/Routes";
import store from "./redux/store";

const CloseButton = ({ closeToast }) => (
  <i onClick={closeToast} className="la la-close notifications-close" />
);

function App({ instance }) {
  return (
    <div className="App">
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        closeButton={<CloseButton />}
      />
      <Provider store={store}>
        <MsalProvider instance={instance}>
          <Routes />
        </MsalProvider>
      </Provider>
    </div>
  );
}

export default App;
