import { AUTH_TYPES } from "../types/auth";
import { LoginApi } from "../services/auth";
import { toast } from "react-toastify";
import { getBrokerDetail } from "../services/auth";

export function loginUser(data, handleModal) {
  return async (dispatch) => {
    dispatch({ type: AUTH_TYPES.LOGIN_REQUEST });
    try {
      const user = await LoginApi(data);
      console.log(user);
      toast.success("Successfully Login");
      localStorage.setItem("authToken", JSON.stringify(user.data?.token));
      setTimeout(() => {
        dispatch({ type: AUTH_TYPES.LOGIN_SUCCESS, payload: user.data });
        handleModal();
      }, 1000);
    } catch (error) {
      const { response } = error;
      console.error(response);
      dispatch({ type: AUTH_TYPES.LOGIN_FAILURE });
      toast.error(response.data?.message || "Something went wrong");
    }
  };
}

export function authLoggedIn(data) {
  return async (dispatch) => {
    dispatch({ type: AUTH_TYPES.IS_LOGIN_SUCCESS, payload: data });
  };
}

export function logoutUser() {
  return async (dispatch) => {
    localStorage.removeItem("authToken");
    dispatch({ type: AUTH_TYPES.LOGOUT_REQUEST });
  };
}

export function getBrokerDetailApi(payload) {
  return async (dispatch) => {
    dispatch({ type: AUTH_TYPES.BROKER_DETAIL_REQUEST });
    try {
      const resp = await getBrokerDetail(payload);
      dispatch({ type: AUTH_TYPES.BROKER_DETAIL_SUCCESS, payload: resp.data?.data });
    } catch {
      dispatch({ type: AUTH_TYPES.BROKER_DETAIL_FAILURE });
    }
  };
}
