import React, { useState } from "react";
import CardSection from "./CardSection";
import { ArrowForward } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { eSignData } from "./CardData";
import Modal from "../../components/Modal";
import Liveliness from "./Liveliness";
import GeolocationPage from "./GeolocationPage";
import OcrPage from "./OcrPage";
import VipvPage from "./VipvPage";

const MyButton = styled(Button)({
  backgroundColor: "#6857e5",
  border: 0,
  width: "auto",
  borderRadius: 3,
  marginTop: "10px",
  color: "white",
  position: "relative",
  bottom: "4px",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#6857e5",
  },
});

const useStyles = makeStyles({
  button: {
    backgroundColor: "#6857e5",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#6857e5",
    },
    width: "40%",
  },
});

const AboutSection = () => {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.loader);
  const [livelinessModal, setLivelinessModal] = useState(false);
  const [loactionModal, setLoactionModal] = useState(false);
  const [ocrModal, setOcrModal] = useState(false);
  const [vipvModal, setVipvModal] = useState(false);

  const aboutList = [
    { icon: "/assets/img/icons/a1.png", title: "Facial Recognition" },
    { icon: "/assets/img/icons/a5.png", title: "Reports and Analytics" },
    { icon: "/assets/img/icons/a2.png", title: "Updated API integration" },
    { icon: "/assets/img/icons/a6.png", title: "Flexible" },
    { icon: "/assets/img/icons/a3.png", title: "Real time verification" },
    { icon: "/assets/img/icons/a7.png", title: "Efficient" },
    { icon: "/assets/img/icons/a4.png", title: "Secured" },
    { icon: "/assets/img/icons/a8.png", title: "Cloud based application" },
  ];

  const useList = [
    <>
      Grow your business with{" "}
      <span className="smart-kyc">
        SMART<b>KYC</b>
      </span>
    </>,
    "Instant client on-boarding",
    "Real time tracking of the client's on-boarding journey",
    "Replace outdated authentication mechanisms",
    "Ensure regulatory compliance",
    "User Management",
    "Reduce paper working",
  ];

  const handleLivelinessModal = () => {
    setLivelinessModal(!livelinessModal);
  };

  const handleLocationModal = () => {
    setLoactionModal(!loactionModal);
  };

  const handleOcrModalModal = () => {
    setOcrModal(!ocrModal);
  };

  const handleVipvModalModal = () => {
    setVipvModal(!vipvModal);
  };

  const handleTryNow = (key) => {
    switch (key) {
      case "1":
        handleLivelinessModal();
        break;
      case "2":
        handleLocationModal();
        break;
      case "3":
        handleOcrModalModal();
        break;
      case "4":
        handleVipvModalModal();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <section className="bg-white py-6">
        <div className="container ">
          <div className="row gx-5 text-center">
            <div className="col-md-12 title-section">
              <div className="upper-round"></div>

              <h2 className="section-title text-start mb-5">
                Features of{" "}
                <span className="sm-kyc">
                  SMART<b>KYC</b>
                </span>{" "}
                System
              </h2>
            </div>
            <div className="col-md-6 ">
              <img
                src="/assets/img/banner/about-banner.svg"
                alt=""
                className="responcive-width"
                width={"450px"}
              />
            </div>
            <div className="col-md-6 p-0">
              <ul className="d-flex flex-wrap list-wrapper kyc-systems">
                {aboutList.map((item, index) => {
                  return (
                    <li key={index} className="w-50 d-flex align-items-center">
                      <img src={item.icon} alt="" className="m-2 system-icon" />
                      <div className="text-start align-items-center system-text">
                        {item.title}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <img src="/assets/img/particle.png" alt="" className="particle-bt-img" />
          </div>
        </div>
      </section>

      <section className=" back-blue background-image setup-section py-5">
        <div className=" container  text-center p-2">
          <div className="row">
            <div className="col-md-6 my-auto">
              <h1 className="brand section-title text-white text-start mb-5 font-gradient">
                SMART<b className="font-gradient kyc">KYC</b>
              </h1>

              <div className="text-start mt-3">
                <h4 className="text-white mt-3 ">
                  <span className="side-border"></span>Simple
                </h4>
                <p className="setup-text">Setup is as simple as setting your email id.</p>
              </div>
              <div className="text-start mt-3">
                <h4 className="text-white mt-3">
                  <span className="side-border "></span>Smart
                </h4>

                <p className="setup-text">
                  Smartly built to meet all your on-boarding requirements.
                </p>
              </div>
              <div className="text-start mt-3 ">
                <h4 className="text-white mt-3">
                  <span className="side-border "></span>Secured
                </h4>

                <p className="setup-text">
                  Built on highly secured Advanced Consent Architecture.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="responcive-hide">
                <img src="/assets/img/smart-kyc.png" alt="" className="smart-kyc-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <CardSection></CardSection>
      <section className="py-6 back-blue " id="smart-systems">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title text-center text-white  font-gradient">
                Use our{" "}
                <span className="brand">
                  SMART<b className="font-gradient kyc">KYC</b>
                </span>{" "}
                System for
              </h2>
            </div>
            <div className="col-md-6">
              <ul className="list-wrapper">
                {useList.map((d, i) => {
                  return (
                    <li key={i} className="text-white py-3 my-2">
                      <span className="side-border"></span>
                      {d}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-6">
              <img src="/assets/img/banner/kyc-bn.svg" alt="" width="100%"></img>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6 svg-squres-bg" id="eSign">
        <div className="container">
          <div className="upper-round"></div>
          <h2 className="section-title  text-black mt-2">
            Built using Cutting-edge technology
          </h2>
          <h6 className="text-grey section-desc">
            eDocuments submission using secure digilocker API
          </h6>
          <div className="row ">
            {eSignData.map((item) => (
              <div className="col-md-6 mt-5" key={item.id}>
                <div className="card" style={{ height: "100%" }}>
                  <div className="row card-body">
                    <div className="col-md-3 col-lg-2 d-flex align-items-center justify-content-center ">
                      <h3 className="slider-counting">{item.counts}</h3>
                    </div>
                    <div className="col-md-9 col-lg-10 ">
                      <div className="sm-card">
                        <h4 className="slider-title">{item.title}</h4>
                        <p className="desc">{item.desc}</p>
                        <MyButton
                          className={classes.button}
                          variant="contained"
                          sx={{ background: "#6857E5" }}
                          onClick={() => handleTryNow(item.id)}
                        >
                          TRY NOW
                          <i className="ms-2" data-feather="arrow-right" />
                        </MyButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-6 back-blue ">
        <div className="d-flex flex-row container ">
          <div className="align-items-center d-flex flex-column justify-content-center">
            <h2 className="section-title  text-white w-100 font-gradient">
              Onboard Customers With Real Time Interaction
            </h2>
            <h3 className="sub-heading text-white">
              <span className="smart-kyc">
                SMART<b>KYC</b>
              </span>{" "}
              is the solution to all your client on-boarding and verification problems
            </h3>
            <div className="text-white mt-3">
              <p className="section-desc">
                Client onboarding has become much easier with the introduction of Aadhar.
                With the use of real-time document verification techniques, OCR-enabled
                image data extraction, facial matching &amp; ML-based liveliness detection
                to automate and execute the KYC verification process, we are taking client
                onboarding to the next step.
              </p>
            </div>
          </div>

          <div className="align-items-center d-flex flex-column justify-content-center w-100 mt-5">
            <img
              src="/assets/img/banner/onboard-img.svg"
              alt=""
              className="responcive-width"
            ></img>
          </div>
        </div>
      </section>

      <section className="border-top border-bottom border-1 py-6 my-5  help-mask border-gradient">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="/assets/img/banner/help-img.svg"
                alt=""
                className="res-img img-fluied"
              ></img>
            </div>

            <div className="col-md-6 my-auto">
              <h2 className="section-title  text-black ">Help and Support </h2>
              <p className=" mb-2 sub-text-p">
                Prompt support to get your setup done easily and to get your customer
                onboarded smoothly with no time.
              </p>
              <div className=" mt-3 sub-text-p">
                <ul className="list-wrapper help-list ">
                  <li className="py-1 my-1">
                    <ArrowForward fontSize="small" style={{ color: "#6857E5" }} />
                    Help you increase your business potential with seamless integration.
                  </li>
                  <li className="py-1 my-1">
                    <ArrowForward fontSize="small" style={{ color: "#6857E5" }} />
                    Dedicated team to meet your business and compliance requirements.
                  </li>
                  <li className="py-1 my-1">
                    <ArrowForward fontSize="small" style={{ color: "#6857E5" }} />
                    Support every API, required for on-boarding your client.
                  </li>
                  <li className="py-1 my-1">
                    <ArrowForward fontSize="small" style={{ color: "#6857E5" }} />
                    Help you at every stage of your client on-boarding journey.
                  </li>
                  <li className="py-1 my-1">
                    <ArrowForward fontSize="small" style={{ color: "#6857E5" }} />
                    Enjoy 24x7 support for all your queries.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title="Liveliness Detection"
        open={livelinessModal}
        onClose={handleLivelinessModal}
        content={<Liveliness handleClose={handleLivelinessModal} />}
        loading={loading}
      />
      <Modal
        title="Geolocation Detection"
        open={loactionModal}
        onClose={handleLocationModal}
        content={<GeolocationPage />}
        loading={loading}
      />
      <Modal
        title="OCR"
        open={ocrModal}
        onClose={handleOcrModalModal}
        content={<OcrPage />}
        loading={loading}
      />
      <Modal
        title="VIPV With Face Matching"
        open={vipvModal}
        onClose={handleVipvModalModal}
        content={<VipvPage handleClose={handleVipvModalModal} />}
        loading={loading}
      />
    </>
  );
};
export default AboutSection;
