export const AUTH_TYPES = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  IS_LOGIN_SUCCESS: "IS_LOGIN_SUCCESS",

  BROKER_DETAIL_REQUEST: "BROKER_DETAIL_REQUEST",
  BROKER_DETAIL_SUCCESS: "BROKER_DETAIL_SUCCESS",
  BROKER_DETAIL_FAILURE: "BROKER_DETAIL_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
};
