import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../pages/Home";
import { b2cPolicies } from "../authConfig";

const BaseRoutes = () => {
  const { instance } = useMsal();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          event.error.errorMessage.indexOf("AADB2C90118") > -1
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            instance
              .loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch((e) => {
                return;
              });
          }
        }
      }
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload) {
          if (
            event.payload.idTokenClaims["acr"] ===
            b2cPolicies.names.forgotPassword
          ) {
            window.alert(
              "Password has been reset successfully. \nPlease sign-in with your new password."
            );
            return instance.logout();
          } else if (
            event.payload.idTokenClaims["acr"] === b2cPolicies.names.editProfile
          ) {
            window.alert(
              "Profile has been edited successfully. \nPlease sign-in again."
            );
            return instance.logout();
          }
        }
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default BaseRoutes;
