import React, { useState } from "react";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import VideoRecorder from "react-video-recorder";
import { useDispatch } from "react-redux";
import { LivelinessApi } from "../../redux/services/user";
import { startLoading, stopLoading } from "../../redux/actions/loader";
import storage from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const SubmitButton = styled(Button)({
  backgroundColor: "#6857e5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#6857e5",
  },
  ":disabled": {
    backgroundColor: "#6857e5",
    color: "#fff",
    opacity: 0.5,
  },
});

const Liveliness = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataResp, setDataResp] = useState();

  const initialValues = {
    video: "",
  };

  const validationSchema = yup.object().shape({
    video: yup.string().required("Upload video is required"),
  });

  const onSubmit = async (value, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setLoading(true);
    dispatch(startLoading());
    const file = new File([value.video], "file0.mp4", { type: "video/mp4" });
    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("percent", percent);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          try {
            const resp = await LivelinessApi(url);
            console.log(resp.data);
            setDataResp(resp.data);
            setLoading(false);
            dispatch(stopLoading());
            resetForm();
          } catch (err) {
            console.log("err", err);
            toast.error(err.response?.data?.message || "Something went wrong");
            setLoading(false);
            dispatch(stopLoading());
            setSubmitting(false);
          }
        });
      }
    );
  };

  return (
    <div className={`my-2 ${loading ? "pointer-none" : ""}`}>
      {dataResp?.status === "Fail" ? (
        <div className="flex flex-col items-center justify-center">
          <h4 className="my-1">
            <b>Status: </b> {dataResp?.status}
          </h4>
          {/* <h5 className="my-1">
            <b>Message: </b> {dataResp?.result}
          </h5> */}
          <SubmitButton type="button" variant="outlined" onClick={() => setDataResp("")}>
            Try Again
          </SubmitButton>
        </div>
      ) : dataResp?.status === "Success" ? (
        <div className="flex flex-col items-center justify-center">
          <h4 className="my-1">
            <b>Status: </b> {dataResp?.status}
          </h4>
          {/* <h5 className="my-1">
            <b>Message: </b> {dataResp?.result}
          </h5> */}
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, dirty, isSubmitting, setFieldValue }) => (
            <FormikForm className="my-2">
              <Form.Group>
                <Form.Label>
                  Upload Video (Please turn your head Left/Right during Video.)
                </Form.Label>
                <VideoRecorder
                  isOnInitially={true}
                  replayVideoAutoplayAndLoopOff={true}
                  onRecordingComplete={(blob) => setFieldValue("video", blob)}
                  onStopReplaying={() => setFieldValue("video", "")}
                  onStartRecording={() => setFieldValue("video", "")}
                  timeLimit={10000}
                  countdownTime={0}
                />
                <ErrorMessage name="video" className="input-error" component="div" />
              </Form.Group>
              <div className="text-end mt-4 mb-2">
                <Button
                  type="button"
                  variant="outlined"
                  className="mx-2"
                  disabled={loading}
                  onClick={() => handleClose()}
                >
                  Close
                </Button>
                <SubmitButton
                  type="submit"
                  variant="outlined"
                  disabled={!isValid || !dirty || isSubmitting || loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </SubmitButton>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </div>
  );
};

export default Liveliness;
