export const eSignData = [
  {
    id: "1",
    counts: "01",
    title: "Liveliness detection",
    desc: "Machine learning - liveliness detection of user.",
    link: "",
    icon: "",
  },
  {
    id: "2",
    counts: "02",
    title: "Geolocation Detection",
    desc: "Detect real time Latitude and Longitude location of user.",
    link: "",
    icon: "",
  },
  {
    id: "3",
    counts: "03",
    title: "OCR",
    desc: "Optical Character Recognition (OCR) is used to scan the information from various KYC documents.",
    link: "",
    icon: "",
  },
  {
    id: "4",
    counts: "04",
    title: "VIPV with face matching",
    desc: "Video Inperson Verification - verify user identity with live VIPV and face matching.",
    link: "",
    icon: "",
  },
];
