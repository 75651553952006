import React, { useState } from "react";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import { BookDemoApi } from "../../redux/services/user";

const SubmitButton = styled(Button)({
  backgroundColor: "#6857e5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#6857e5",
  },
  ":disabled": {
    backgroundColor: "#6857e5",
    color: "#fff",
    opacity: 0.5,
  },
});

const BookDemo = ({ handleModal }) => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    details: "",
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().min(10).required("Phone is required"),
    details: yup.string().required("Details is required"),
  });

  const onSubmit = async (value, { setSubmitting }) => {
    setSubmitting(true);
    setLoading(true);
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("email", value.email);
    formData.append("phone", value.phone);
    formData.append("details", value.details);
    try {
      const resp = await BookDemoApi(formData);
      console.log(resp.data);
      setTimeout(() => {
        setLoading(false);
        handleModal();
        MySwal.fire({
          title: "Thank you, Team Secmark will contact you soon.",
          icon: "success",
          showCloseButton: false,
          showConfirmButton: false,
          timer: 5000,
        });
      }, 1000);
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const onKeyPress = (e) => {
    if (e.which !== 0 && (e.which < 48 || e.which > 57)) {
      e.preventDefault();
    }
  };

  return (
    <div className={`my-2 ${loading ? "pointer-none" : ""}`}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <FormikForm className="my-2">
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Field
                name="name"
                render={({ field }) => (
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter name"
                    value={field.value}
                    onChange={field.onChange}
                    maxLength={50}
                    autoFocus={true}
                  />
                )}
              />
              <ErrorMessage
                name="name"
                className="input-error"
                component="div"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Field
                name="email"
                render={({ field }) => (
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder="Enter email address"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <ErrorMessage
                name="email"
                className="input-error"
                component="div"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone number</Form.Label>
              <Field
                name="phone"
                render={({ field }) => (
                  <Form.Control
                    name="phone"
                    type="text"
                    placeholder="Enter phone number"
                    value={field.value}
                    onChange={field.onChange}
                    onKeyPress={onKeyPress}
                    maxLength={10}
                  />
                )}
              />
              <ErrorMessage
                name="phone"
                className="input-error"
                component="div"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Details</Form.Label>
              <Field
                name="details"
                render={({ field }) => (
                  <Form.Control
                    name="details"
                    type="text"
                    as="textarea"
                    placeholder="Enter details"
                    value={field.value}
                    onChange={field.onChange}
                    maxLength={150}
                  />
                )}
              />
              <ErrorMessage
                name="details"
                className="input-error"
                component="div"
              />
            </Form.Group>
            <div className="text-end mt-4 mb-2">
              <Button
                type="reset"
                variant="outlined"
                className="mx-2"
                disabled={loading}
              >
                Clear
              </Button>
              <SubmitButton type="submit" variant="outlined" disabled={loading}>
                {loading ? "Loading..." : "Submit"}
              </SubmitButton>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default BookDemo;
