import { LOADER_TYPES } from "../types/loader";

const initialState = {
  loading: false,
};

export function loader(state = initialState, action) {
  switch (action.type) {
    case LOADER_TYPES.LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case LOADER_TYPES.LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
