import { API_URL_KYC } from "../../config";
import axios from "axios";

const headers = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
};

// book demo api
export const BookDemoApi = (payload) => {
  return axios.post(
    `${API_URL_KYC}/api/method/central_system.apis.broker_signup_page.api.bookdemo`,
    payload,
    headers
  );
};

// kyc api
export const LivelinessApi = (videoimg) => {
  return axios.post(
    `${API_URL_KYC}/api/method/central_system.apis.smart_kyc.api.liveliness_detection_of_user`,
    { videoimg }
  );
};

export const GeolocationApi = (payload) => {
  return axios.post(
    `${API_URL_KYC}/api/method/central_system.apis.smart_kyc.api.geolocation_detection`,
    payload,
    headers
  );
};

export const OcrApi = (payload) => {
  return axios.post(
    `${API_URL_KYC}/api/method/central_system.apis.smart_kyc.api.ocr_document`,
    payload,
    headers
  );
};

export const VipvApi = (payload) => {
  return axios.post(
    `${API_URL_KYC}/api/method/central_system.apis.smart_kyc.api.upload_visual_verification`,
    payload,
    headers
  );
};
