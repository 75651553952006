import React, { useState, useEffect } from "react";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import {
  SignupApi,
  verifySignupOtpApi,
  verifySignupDetailApi,
  resendOtpApi,
} from "../../redux/services/auth";

const SubmitButton = styled(Button)({
  backgroundColor: "#6857e5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#6857e5",
  },
  ":disabled": {
    backgroundColor: "#6857e5",
    color: "#fff",
    opacity: 0.5,
  },
});

const Register = ({ handleModal }) => {
  const MySwal = withReactContent(Swal);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState();
  const [isToken, setIsToken] = useState(null);
  const [otpVerified, setOtpVerified] = useState({
    emailOtp: false,
    phoneOtp: false,
  });
  const [mobileOtp, setMobileOtp] = useState(false),
    [emailOtp, setEmailOtp] = useState(false),
    [mobileTimer, setMobileTimer] = useState(60),
    [emailTimer, setEmailTimer] = useState(60);
  const [verifyStatus, setVerifyStatus] = useState();

  const handleRegisterOtp = async (value, { setSubmitting }) => {
    setSubmitting(true);
    setLoading(true);
    const payload = {
      broker_name: value.brokerName,
      email: value.email,
      phone: `91${value.phone}`,
    };
    try {
      const resp = await SignupApi(payload);
      console.log(resp?.data);
      setIsToken(resp?.data?.data?.token);
      setMobileOtp(true);
      setEmailOtp(true);
      setRegister(value);
      setStep(1);
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      toast.error(err.response?.data?.message || "Something went wrong");
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (payload) => {
    setLoading(true);
    try {
      const resp = await verifySignupOtpApi(payload);
      console.log(resp?.data);
      setVerifyStatus(resp?.data);
      if (payload.type === "email") {
        setOtpVerified({ ...otpVerified, emailOtp: true });
      }
      if (payload.type === "phone") {
        setOtpVerified({ ...otpVerified, phoneOtp: true });
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleSignup = async (value, { setSubmitting }) => {
    setSubmitting(true);
    setLoading(true);
    console.log(value);
    const payload = {
      name: value.name,
      company_name: value.company,
      position: value.position,
      password: value.password,
    };
    const headers = {
      headers: {
        Authorization: `Basic ${verifyStatus.token}`,
      },
    };
    try {
      const resp = await verifySignupDetailApi(payload, headers);
      console.log(resp?.data);
      setTimeout(() => {
        setLoading(false);
        handleModal();
        MySwal.fire({
          title: "Register successfully",
          icon: "success",
          showCloseButton: true,
          showConfirmButton: false,
          timer: 5000,
        });
      }, 1000);
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (otpVerified.emailOtp && otpVerified.phoneOtp) {
      setStep(2);
    }
  }, [otpVerified]);

  const handleEmailOtp = (e, setFieldValue) => {
    setFieldValue("emailOtp", e.target.value);
    if (e.target.value.length === 6) {
      const payload = {
        token: isToken,
        type: "email",
        otp: e.target.value,
      };
      handleVerifyOtp(payload);
    }
  };

  const handlePhoneOtp = (e, setFieldValue) => {
    setFieldValue("phoneOtp", e.target.value);
    if (e.target.value.length === 6) {
      const payload = {
        token: isToken,
        type: "phone",
        otp: e.target.value,
      };
      handleVerifyOtp(payload);
    }
  };

  const onKeyPress = (e) => {
    if (e.which !== 0 && (e.which < 48 || e.which > 57)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    let intervalId;
    if (emailTimer > 0 && emailOtp) {
      intervalId = setInterval(() => {
        setEmailTimer((counter) => counter - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [emailTimer, emailOtp]);

  useEffect(() => {
    let intervalId;
    if (mobileTimer > 0 && mobileOtp) {
      intervalId = setInterval(() => {
        setMobileTimer((counter) => counter - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [mobileTimer, mobileOtp]);

  const resetEmailTimer = (value) => {
    const payload = {
      token: value.email,
      send_email_otp: true,
      send_phone_otp: false,
    };
    handleResendOtp(payload);
  };

  const resetMobileTimer = (value) => {
    const payload = {
      token: `91${value.phone}`,
      send_email_otp: false,
      send_phone_otp: true,
    };
    handleResendOtp(payload);
  };

  const handleResendOtp = async (payload) => {
    try {
      const resp = await resendOtpApi(payload);
      console.log(resp.data);
      if (payload.send_phone_otp) {
        console.log(payload.send_phone_otp);
        setMobileTimer(60);
      }
      if (payload.send_email_otp) {
        setEmailTimer(60);
      }
      toast.success("Otp resend successfully");
    } catch (error) {
      const { response } = error;
      console.log("err", response);
      toast.error(response?.data?.message || "Something went wrong");
    }
  };

  return (
    <div className={`my-2 ${loading ? "pointer-none" : ""}`}>
      {step === 0 ? (
        <Formik
          enableReinitialize={true}
          initialValues={{
            brokerName: "",
            email: "",
            phone: "",
          }}
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .email("Invalid email")
              .required("Email is required"),
            phone: yup.string().min(10).required("Phone is required"),
          })}
          onSubmit={handleRegisterOtp}
        >
          {() => (
            <FormikForm className="my-2">
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Field
                  name="email"
                  render={({ field }) => (
                    <Form.Control
                      name="email"
                      type="text"
                      placeholder="Enter email address"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <ErrorMessage
                  name="email"
                  className="input-error"
                  component="div"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone number</Form.Label>
                <Field
                  name="phone"
                  render={({ field }) => (
                    <Form.Control
                      name="phone"
                      type="text"
                      placeholder="Enter phone number"
                      value={field.value}
                      onChange={field.onChange}
                      onKeyPress={onKeyPress}
                      maxLength={10}
                    />
                  )}
                />
                <ErrorMessage
                  name="phone"
                  className="input-error"
                  component="div"
                />
              </Form.Group>
              <div className="text-end my-2">
                <Button
                  type="reset"
                  variant="outlined"
                  className="mx-2"
                  disabled={loading}
                >
                  Clear
                </Button>
                <SubmitButton
                  type="submit"
                  variant="outlined"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </SubmitButton>
              </div>
            </FormikForm>
          )}
        </Formik>
      ) : step === 1 ? (
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: register?.email,
            phone: register?.phone,
            emailOtp: "",
            phoneOtp: "",
          }}
          validationSchema={yup.object().shape({
            emailOtp: yup.string().min(6).required("Email Otp is required"),
            phoneOtp: yup.string().min(6).required("Phone Otp is required"),
          })}
        >
          {({ values, setFieldValue }) => (
            <FormikForm className="my-2">
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Field
                  name="email"
                  render={({ field }) => (
                    <Form.Control
                      name="email"
                      type="text"
                      placeholder="Enter email address"
                      value={field.value}
                      onChange={field.onChange}
                      disabled
                    />
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone number</Form.Label>
                <Field
                  name="phone"
                  render={({ field }) => (
                    <Form.Control
                      name="phone"
                      type="text"
                      placeholder="Enter phone number"
                      value={field.value}
                      onChange={field.onChange}
                      disabled
                    />
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email Otp</Form.Label>
                <Field
                  name="emailOtp"
                  render={({ field }) => (
                    <Form.Control
                      name="emailOtp"
                      type="text"
                      placeholder="Enter email OTP"
                      value={field.value}
                      onChange={(e) => handleEmailOtp(e, setFieldValue)}
                      maxLength={6}
                      disabled={otpVerified.emailOtp}
                    />
                  )}
                />
                <ErrorMessage
                  name="emailOtp"
                  className="input-error"
                  component="div"
                />
                {!otpVerified.emailOtp && (
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-sm mt-2"
                      disabled={emailTimer > 0}
                      onClick={() => resetEmailTimer(values)}
                    >
                      Resend OTP
                      {emailTimer > 0 ? " after " + emailTimer + " second" : ""}
                    </button>
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone Otp</Form.Label>
                <Field
                  name="phoneOtp"
                  render={({ field }) => (
                    <Form.Control
                      name="phoneOtp"
                      type="text"
                      placeholder="Enter phone OTP"
                      value={field.value}
                      onChange={(e) => handlePhoneOtp(e, setFieldValue)}
                      maxLength={6}
                      disabled={otpVerified.phoneOtp}
                    />
                  )}
                />
                <ErrorMessage
                  name="phoneOtp"
                  className="input-error"
                  component="div"
                />
                {!otpVerified.phoneOtp && (
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-sm mt-2"
                      disabled={mobileTimer > 0}
                      onClick={() => resetMobileTimer(values)}
                    >
                      Resend OTP
                      {mobileTimer > 0
                        ? " after " + mobileTimer + " second"
                        : ""}
                    </button>
                  </div>
                )}
              </Form.Group>
              {/* <div className="text-end my-2">
                <Button type="reset" variant="outlined" className="mx-2">
                  Clear
                </Button>
                <SubmitButton type="submit" variant="outlined" disabled={loading}>
                   {loading ? "Loading..." : "Submit"}
                </SubmitButton>
              </div> */}
            </FormikForm>
          )}
        </Formik>
      ) : step === 2 ? (
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: "",
            company: "",
            position: "",
            password: "",
          }}
          validationSchema={yup.object().shape({
            name: yup.string().required("Name is required"),
            company: yup.string().required("Company is required"),
            position: yup.string().required("Position is required"),
            password: yup.string().min(6).required("Password is required"),
          })}
          onSubmit={handleSignup}
        >
          {() => (
            <FormikForm className="my-2">
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Field
                  name="name"
                  render={({ field }) => (
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Enter name"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <ErrorMessage
                  name="name"
                  className="input-error"
                  component="div"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Company</Form.Label>
                <Field
                  name="company"
                  render={({ field }) => (
                    <Form.Control
                      name="company"
                      type="text"
                      placeholder="Enter company"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <ErrorMessage
                  name="company"
                  className="input-error"
                  component="div"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Position</Form.Label>
                <Field
                  name="position"
                  render={({ field }) => (
                    <Form.Control
                      name="position"
                      type="text"
                      placeholder="Enter position"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <ErrorMessage
                  name="position"
                  className="input-error"
                  component="div"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Field
                  name="password"
                  render={({ field }) => (
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Enter password"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <ErrorMessage
                  name="password"
                  className="input-error"
                  component="div"
                />
              </Form.Group>
              <div className="text-end my-2">
                <Button
                  type="reset"
                  variant="outlined"
                  className="mx-2"
                  disabled={loading}
                >
                  Clear
                </Button>
                <SubmitButton
                  type="submit"
                  variant="outlined"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </SubmitButton>
              </div>
            </FormikForm>
          )}
        </Formik>
      ) : (
        ""
      )}
    </div>
  );
};

export default Register;
