import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { makeStyles } from "@material-ui/core/styles";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "red",
  },
});

const OutLineButton = styled(Button)({
  backgroundColor: "#050217",
  borderRadius: 3,
  color: "#6857e5",
  "&:hover": {
    backgroundColor: "#6857e5",
    color: "white",
  },
  "&:disabled": {
    backgroundColor: "gray",
    color: "white",
  },
});

const drawerWidth = 320;

function MobileMenu({ isOpen, toggleOpen, instance, isBroker, broker }) {
  const classes = useStyles();
  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        className="sidebar-box"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={toggleOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              background: "#000000",
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <span className="cross-icon" style={{ color: "#ffffff" }} onClick={toggleOpen}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>

          <List sx={{ backgroundColor: "#000000", color: "#fff", display: "none" }}>
            {["Home", "Pricing"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ color: "#ffffff" }}>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <List style={{ margin: 0 }}>
            <AuthenticatedTemplate>
              <ListItem>
                <OutLineButton
                  className={classes.button}
                  variant="contained"
                  sx={{
                    color: "#fff",
                    border: "1px solid #fff",
                    padding: "8px 30px",
                  }}
                  onClick={() =>
                    window.open(
                      broker?.redirect_url ? `${broker.redirect_url}sso_login` : "#",
                      "_blank"
                    )
                  }
                  disabled={isBroker}
                >
                  Go to dashboard
                </OutLineButton>
              </ListItem>
              <ListItem>
                <OutLineButton
                  className={classes.button}
                  variant="contained"
                  sx={{
                    color: "#fff",
                    border: "1px solid #fff",
                    padding: "8px 30px",
                  }}
                  onClick={() =>
                    instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    })
                  }
                >
                  LOGOUT
                </OutLineButton>
              </ListItem>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <ListItem>
                <OutLineButton
                  className={classes.button}
                  variant="contained"
                  sx={{
                    color: "#fff",
                    border: "1px solid #fff",
                    padding: "8px 30px",
                  }}
                  onClick={() => instance.loginRedirect(loginRequest)}
                >
                  LOGIN
                </OutLineButton>
              </ListItem>
            </UnauthenticatedTemplate>
          </List>
        </Drawer>
      </Box>
    </>
  );
}

export default MobileMenu;
