import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import {
  useMsal,
  useAccount,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Modal from "../Modal";
import Register from "../../pages/Register";
import Login from "../../pages/Login";
import BookDemo from "../../pages/BookDemo";
import MobileMenu from "./MobileMenu";
import {
  authLoggedIn,
  logoutUser,
  getBrokerDetailApi,
} from "../../redux/actions/auth";
import { loginRequest } from "../../authConfig";

const OutLineButton = styled(Button)({
  backgroundColor: "#050217",
  borderRadius: 3,
  color: "#6857e5",
  "&:hover": {
    backgroundColor: "#6857e5",
    color: "white",
  },
  "&:disabled": {
    backgroundColor: "gray",
    color: "white",
  },
});

const useStyles = makeStyles({
  button: {
    backgroundColor: "#6857e5",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#6857e5",
    },
  },
});

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { isBroker, broker } = useSelector((state) => state.auth);
  const [registerModal, setRegisterModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [bookDemoModal, setBookDemoModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [openSideBar, setOpenSidebar] = useState(false);

  const handleBookDemoModal = () => {
    setBookDemoModal(!bookDemoModal);
  };

  const handleRegisterModal = () => {
    setRegisterModal(!registerModal);
  };

  const handleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const handleTry = () => {
    document.getElementById("eSign").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!!localStorage.getItem("authToken")) {
      dispatch(authLoggedIn({ isLoggedIn: true }));
    }
  }, [dispatch]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(false);
      setOpenSidebar(false);
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (account?.idTokenClaims) {
      const { email } = account.idTokenClaims || {};
      console.log(email);
      dispatch(getBrokerDetailApi({ email }));
    }
  }, [account]);

  const HandleClick = () => {
    setIsMobile(!isMobile);
    setOpenSidebar(!openSideBar);
  };

  return (
    <>
      <nav
        className={`navbar navbar-head navbar-expand-lg navbar-expand-lg navbar-trasparent bg-transparent`}
        style={{ zIndex: 10 }}
      >
        <div className="container">
          <Link className="navbar-brand text-white m-0" to="/">
            <img
              src="/assets/img/kyc-logo.svg"
              alt="logo"
              className="img-responsive"
            />
          </Link>
          <button
            type="button"
            className="navbar-toggler bg-white ml-auto"
            onClick={() => HandleClick()}
            style={{ marginLeft: "auto" }}
          >
            {/* {isMobile ? (
              <span>
                <i className="fas fa-close" />
              </span>
            ) : (
              <span className="navbar-toggler-icon"></span>
            )} */}
            <span className="navbar-toggler-icon"></span>
            <span style={{ color: "red" }}>{openSideBar}</span>
          </button>
          <div
            className={`collapse navbar-collapse  ${isMobile ? "show" : ""}`}
          >
            {/* openSideBar,setOpenSidebar */}
            <MobileMenu
              isOpen={openSideBar}
              toggleOpen={HandleClick}
              instance={instance}
              isBroker={isBroker}
              broker={broker}
            />
            {!isMobile && (
              <>
                <ul className="navbar-nav">
                  <li className="nav-item d-none">
                    <Link className="nav-link " to="/">
                      HOME
                    </Link>
                  </li>
                  <li className="nav-item d-none">
                    <Link className="nav-link" to="/">
                      PRICING
                    </Link>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <AuthenticatedTemplate>
                    <li className="nav-item mx-1">
                      <OutLineButton
                        className={classes.button}
                        variant="contained"
                        sx={{
                          color: "#fff",
                          border: "1px solid #fff",
                          padding: "8px 30px",
                          width: "100% !important",
                        }}
                        onClick={() =>
                          window.open(
                            broker?.redirect_url
                              ? `${broker.redirect_url}sso_login`
                              : "#",
                            "_blank"
                          )
                        }
                        disabled={isBroker}
                      >
                        Go to dashboard
                      </OutLineButton>
                    </li>
                    <li className="nav-item mx-1">
                      <OutLineButton
                        className={classes.button}
                        variant="contained"
                        sx={{
                          color: "#fff",
                          border: "1px solid #fff",
                          padding: "8px 30px",
                        }}
                        onClick={() =>
                          instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                          })
                        }
                      >
                        LOGOUT
                      </OutLineButton>
                    </li>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <li className="nav-item mx-1">
                      <OutLineButton
                        className={classes.button}
                        variant="contained"
                        sx={{
                          color: "#fff",
                          border: "1px solid #fff",
                          padding: "8px 30px",
                        }}
                        onClick={() => instance.loginRedirect(loginRequest)}
                      >
                        LOGIN
                      </OutLineButton>
                    </li>
                  </UnauthenticatedTemplate>
                </ul>
              </>
            )}
          </div>
        </div>
      </nav>

      {/* Header */}
      <header
        className="page-header-ui page-header-ui-dark background-gradient background-image"
        style={{ marginTop: "-100px", zIndex: 2 }}
      >
        <div className="page-header-ui-content pt-5 bg-block-img">
          <div className="container">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6" data-aos="fade-up">
                <h1 className="page-header-ui-title">
                  Onboard client in real-time using AI-based{" "}
                  <span className="brand font-gradient">
                    SMART<b className="font-gradient kyc">KYC</b>
                  </span>
                </h1>
                <p className="page-header-ui-text mb-5">
                  <span className="smart-kyc">
                    SMART<b>KYC</b>
                  </span>{" "}
                  brings you integrated APIs from PAN verification till digital
                  signature of KYC form.
                </p>

                <Stack spacing={2} direction="row" className="banner-btns ">
                  <OutLineButton
                    className={classes.button}
                    variant="contained"
                    sx={{ color: "#fff", border: "1px solid #fff" }}
                    onClick={() => handleBookDemoModal()}
                  >
                    BOOK A DEMO
                    <i className="ms-2" data-feather="arrow-right" />
                  </OutLineButton>

                  <OutLineButton
                    className={classes.button}
                    variant="contained"
                    sx={{
                      color: "#fff",
                      border: "1px solid #fff",
                      padding: "0.5rem 2rem",
                    }}
                    onClick={() => handleTry()}
                  >
                    TRY NOW
                  </OutLineButton>
                </Stack>
              </div>
              <div
                className="col-lg-6 d-none d-lg-block"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <img
                  className="img-fluid"
                  src={"/assets/img/banner/banner.svg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <Modal
        title="Register"
        open={registerModal}
        onClose={handleRegisterModal}
        content={<Register handleModal={handleRegisterModal} />}
      />
      <Modal
        title="Login"
        open={loginModal}
        onClose={handleLoginModal}
        content={<Login handleModal={handleLoginModal} />}
      />
      <Modal
        title="Book a demo"
        open={bookDemoModal}
        onClose={handleBookDemoModal}
        content={<BookDemo handleModal={handleBookDemoModal} />}
      />
    </>
  );
};
export default Header;
